<template>
  <div class="container">
    <template v-if="!loading && !empty">
      <div class="btn_container" @click="addFeedback">
        <a-icon type="phone" />
        <span>报障报修</span>
      </div>
      <div class="top_container"><a-icon @click="goProductList" class="back_btn" type="left"/>
      {{ single.enterprise ? single.enterprise.name : '' }}</div>
      <div v-if="single.avatar" class="product_container">
        <img :src="single.avatar">
      </div>
      <div class="desc_container">
        <div class="title">产品概要</div>
        <div class="desc_item">
          <div class="name">产品类别</div>
          <div class="value">
            {{ product.one_level_category ? product.one_level_category.name : '' }}
            {{ product.two_level_category ? '/' + product.two_level_category.name : '' }}
            {{ product.three_level_category ? '/' + product.three_level_category.name : '' }}
          </div>
        </div>
        <div class="desc_item">
          <div class="name">产品名</div>
          <div class="value">{{ product.name }}</div>
        </div>
        <div class="desc_item">
          <div class="name">产品编号</div>
          <div class="value">{{ single.code }}</div>
        </div>
        <div class="desc_item">
          <div class="name">二维码编号</div>
          <div class="value">{{ single.qr_code ? single.qr_code.code : '' }}</div>
        </div>
        <div class="desc_item">
          <div class="name">质保日期</div>
          <div class="value" style="display: flex; align-items: center">
            <span style="margin-right: 5px;">{{ single.guarantee_date ? single.guarantee_date : '暂无' }}</span>
            <a-tag style="margin-right: 0" v-if="single.in_guarantee === 1" color="green">保内</a-tag>
            <a-tag style="margin-right: 0" v-else-if="single.in_guarantee === 2" color="pink">保外</a-tag>
            <a-tag style="margin-right: 0" v-else color="orange">未知</a-tag>
          </div>
        </div>
        <div v-if="single.resp_user" class="user_container">
          <div class="user_info">
            <img :src="single.resp_user.avatar ? single.resp_user.avatar : DefaultAvatar">
            <div class="info">
              <div style="color: #000">{{ single.resp_user.real_name }}</div>
              <a style="color: #3263f2" :href="'tel:' + single.resp_user.mobile">{{ single.resp_user.mobile }}</a>
            </div>
          </div>
          <div class="job">业务经理</div>
        </div>
      </div>
      <div class="desc_container">
        <div class="title">产品详情</div>
        <extend-field-list :field-list="single.fieldValueList"></extend-field-list>
        <div class="profile_container" v-html="single.profile"></div>
      </div>
      <div class="bottom_container">
        <div class="current">
          <a-icon type="appstore" />
          <span>产品信息</span>
        </div>
        <div @click="goHistory">
          <a-icon type="customer-service" />
          <span>维修记录</span>
        </div>
        <div @click="goUser" style="border-left: 1px solid #e6e6e6;">
          <a-icon type="interaction" />
          <span>用户</span>
        </div>
      </div>
    </template>
    <div class="loading" v-if="loading"><a-icon type="loading" /></div>
    <div class="empty_container" v-if="empty">
      <img src="@/assets/image/empty.png" alt="">
      <span>此二维码未绑定产品</span>
    </div>
  </div>
</template>
<script>
import DefaultAvatar from '@/assets/image/default_avatar.png'
import ExtendFieldList from '@/components/ExtendField/list'
import request from '@/utils/request';
import cookies from 'vue-cookies';
import enums from "../utils/enums";
export default {
  components: { ExtendFieldList },
  data () {
    return {
      loading: true,
      single: {},
      product: {},
      qrId: 0,
      DefaultAvatar,
      empty: false
    }
  },
  created: function () {
    if(cookies.get('customer_id')==null || cookies.get('customer_id')=="" || cookies.get('customer_id')=='undefined'){
        this.$router.replace({ path: '/login', query: {}})
    }
    const qrId = this.$route.query.qr_id
    if (!qrId) {
      return
    }
    this.qrId = qrId
    request({
      url: 'single/detail_by_qr_id',
      data: { qr_id: qrId }
    }).then(r => {
      if (r.data.data.single) {
        this.single = r.data.data.single
        this.product = r.data.data.goods
      } else {
        this.empty = true
      }
      this.loading = false
    })
  },
  methods: {
    goHistory () {
      this.$router.replace({ path: '/h', query: {
        enterpriseId: this.single.enterprise.id,
        singleProductId: this.single.id,
        qr_id: this.qrId
      }})
    },
    addFeedback () {
      this.$router.push({ path: '/af', query: {
        enterpriseId: this.single.enterprise.id,
        customerId: this.single.customer_id,
        singleProductId: this.single.id,
        qrId: this.qrId
      }})
    },
    getField (list) {
      const r = []
      list.forEach(item => {
        let field = {
          label: item.field.label,
          type: item.field.type
        }
        if (item.field.type === 'file'){
          field.value = JSON.parse(item.the_value)
        } else {
          field.value = item.the_value
        }
        r.push(field)
      })
      return r
    },
    goUser () {
      this.$router.replace({ path: '/user', query: {}})
    },
    goProductList () {
      this.$router.replace({ path: '/single', query: {
        enterpriseId: enums.STATE.ENTERPRISE_ID.v
      }})
    }
  }
}
</script>
<style lang="less">
.ant-drawer-body{
  height: 200px;
  overflow: auto;
  padding: 12px;
}
.profile_container{
  padding: 10px;
  overflow: hidden;
  word-wrap:break-word;
  word-break:break-all;
  img{
    max-width: 100% !important;
  }
  p{
    margin-bottom: 0;
  }
}
</style>
<style lang="less" scoped>
.container{
  padding: 8px;
  padding-bottom: 60px;
  padding-top: 58px;
  .product_container{
    font-size: 16px;
    margin-bottom: 8px;
    padding: 8px;
    background-color: #fff;
    img{
      width: 100%;
    }
  }
  .desc_container{
    margin-bottom: 8px;
    background-color: #fff;
    .title{
      border-left: 4px solid #3263f2;
      padding-left: 10px;
      height: 44px;
      line-height: 44px;
      font-size: 18px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid #e6e6e6;
    }
    .desc_item{
      display: flex;
      padding: 10px 0 10px 14px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 16px;
      .name{
        color: #999;
        width: 80px;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 8px;
      }
      .value{
        color: #222;
        flex: 1;
        overflow: hidden;
        word-wrap:break-word; white-space:normal; word-break:break-all;
      }
    }
  }
  .user_container{
    padding: 14px;
    display: flex;
    justify-content: space-between;
    .user_info{
      display: flex;
      font-size: 18px;
      img{
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 2px;
      }
    }
    .job{
      padding: 0 10px;
      font-size: 12px;
      background-color: #eeeffe;
      display: inline-flex;
      height: 22px;
      line-height: 22px;
      text-align: center;
      border-radius: 10px;
      color: #3263f2;
    }
  }
}
.feedback_type_container{
  display: flex;
  flex-wrap: wrap;
  div {
    background-color: #3263f2;
    color: #fff;
    border-radius: 10px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    padding: 0 16px;
    margin-right: 6px;
    margin-bottom: 10px;
  }
}
.loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240,242,245, 1);
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.empty_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    margin-top: 30%;
    width: 80%;
  }
}
.btn_container{
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 56px;
  height: 50px;
  border-radius: 6px;
  background-color: #ff4d4f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  font-size: 12px;
  i{
    font-size: 22px;
    margin-top: 3px;
  }
}
</style>
